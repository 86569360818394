import SiteHero from "../../components/Hero/siteHeadingHero";

const Somos = () => {
    const url = require('../../assets/howard-bouchevereau-vHnQqO3TT4c-unsplash.jpg');
    const heading = "Un equipo de expertos a tu servicio"
    const subHeading = "Comagro nace de la necesidad de generar un cambio en el modelo<br>tradicional de venta de insumos agrícolas en Chile, con un claro<br>enfoque en el agricultor."
    return (
    <di>
        <SiteHero heading={heading} subheading={subHeading} imageurl={url}/>
        <div>
            <div className="container-qs">
                <p className="text-qs">Comagro es una empresa que ofrece soluciones verdes de calidad, eficientes, de alto impacto agronómico y que dejan una mínima huella ambiental a largo plazo.<br></br><br></br>
                    Nace en Chile con espíritu de motivación, crecimiento y con una fórmula única que le permite escalar en diferentes latitudes y cumplir con su promesa: ofrecer un futuro mejor para las próximas generaciones, a través de una mentalidad bio.<br></br>
                    Comagro tiene resultados comprobados a lo largo de sus años de experiencia, y ha logrado convertirse en un actor clave del mercado chileno; porque cuenta con un equipo de profesionales de primer nivel, de alta calidad humana y técnica, atento a lo que está pasando en el mundo, de manera que pueda atender las necesidades reales y específicas de cada cliente con estrategias y productos específicos para que sus cosechas prosperen.<br></br>
                    Para Comagro, el humano está en el centro, por eso, escucha activamente a sus clientes, los entiende y se adapta a ellos desde el primer contacto; les ofrece una experiencia y una atención personalizada de manera que se fomente una relación de alianza duradera y próspera en pro de un gran objetivo común.<br></br></p>
            </div>
            <div className="container-qs">
            <video controls className="video-container">
              <source src={require('../../assets/videos/comagro-usa.mp4')} type="video/mp4" />
            </video>
            </div>

        </div>
    </di>
    )
}

export default Somos;