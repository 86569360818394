import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToCart from "../../cart/add-to-cart";

export const Product = ({ product }) => {
  const ficha = product.downloads[0] ? product.downloads[0].file : "";
  const [isMounted, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, []);
  return (
    <div
      key={product.id}
      className="grid md:max-h-[320px] w-auto rounded-[16px] bg-white text-center border-2 border-hero-color hover:border-secondary group relative"
    >
      <div className="flex flex-col md:flex-lg-row-reverse pb-4 mx-auto lg:py-0 lg:flex-row justify-content-center justify-center align-center">
        <div className="flex justify-content-center items-center justify-start mx-auto mt-2 lg:mt-0 max-h-[202px] lg:h-full xl:h-full 2xl:h-full">
          <img
            src={product.images[0] ? product.images[0].src : ""}
            alt=""
            className="object-contain max-h-[203px] w-[203px] align-center"
          />
        </div>
        <div className="flex flex-col lg:w-[55%] sm:py-1 lg:text-left p-2 mx-auto">
          <h2 className="comagro-blue text-[16px] lg:text-left font-semibold lg:pb-[16px]">
            {product.name}
          </h2>
          {isMounted ? (
            <p
              className="text-[12px] lg:text-left m-0 lg:text-[14px]"
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            />
          ) : null}
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0">
        <div className="grid grid-cols-3 border-2 max-h-[36px] rounded-b-[13px] border-transparent px-2 bg-sol-grey group-hover:bg-secondary lg:text-[14px] text-[10px] font-semibold">
          <div className="flex flex-wrap my-auto">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              className=" mx-auto "
              to={ficha}
              style={{
                textDecoration: "none",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color: "#000",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-[18px] h-[21px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <p className="pl-2 my-auto">Ficha Técnica</p>
            </Link>
          </div>
          <AddToCart product={product} />
        </div>
      </div>
    </div>
  );
};
