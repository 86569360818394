import { dataEquipo } from "../../utils/data"
import SiteHero from "../../components/Hero/siteHeadingHero";
import { useEffect } from "react";
import CardEquipo from "../../components/cardEquipo";

const Equipo = () => {
    useEffect(()=>{
    },[])
    return (
    <div>
        <SiteHero heading={dataEquipo.title} subheading={dataEquipo.subheading} imageurl={dataEquipo.img}/>
        <CardEquipo dataequipo={dataEquipo.equipo}/>
    </div>
    )
}

export default Equipo;