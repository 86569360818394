import Services from "../components/cardServices";
import HeroHome from "../components/Hero/heroHome";
import SeasonProducts from "../components/seasonProducts";
import CarouselHome from "../components/Carousel/carousel";

const Home = () => {
    return (
        <div>
            <HeroHome/>
            <CarouselHome/>
            <Services/>
            <SeasonProducts/>
        </div>
    )
}
export default Home;