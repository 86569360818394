export const dataEquipo = {
    img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/markus-spiske-O70hwncRDC8-unsplash-scaled.jpg`,
    title: "COMAGRO, se forma por Personas de Excelencia",
    subheading:
      "Contamos con un Equipo Técnico multidisciplinario, de gran prestigio y trayectoria en la Agricultura nacional e internacional para el desarrollo en terreno de toda su línea de Productos.",
    equipo: [
      {
        nombre: "Equipo",
        color: "bg-hero-color",
        personas: [
          {
            nombre: "Benjamín Troncoso",
            cargo: "GENERAL MANAGER CIVIL ENGINEER",
            mail: "btroncoso@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/1-Benjamin-Troncoso-Comagro-e1679345805532.jpg`,
          },
          {
            nombre: "Pía Bastías",
            cargo: "COORDINATOR INTERNATIONAL TECHNIQUE",
            mail: "piabastias@comagrochile.cl",
            tel: "+56 9 9452 4669",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Pia_Bastias_Comagro-1-e1679345928217.jpg`,
          },
          {
            nombre: "Felipe Larraín",
            cargo: "FOUNDERS/PRESIDENT ENTERPRENEUR",
            mail: "flarrain@comagrochile.cl",
            tel: "",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/5-Felipe-Larrain-Comagro-e1679346228976.jpg`,
          },
          {
            nombre: "Valentina Laurido",
            cargo: "HEAD OF RESEARCH AND DEVELOPMENT INTERNATIONAL",
            mail: "vlaurido@comagrochile.cl",
            tel: "+56 9 8216 8243",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/Valentina_Laurido-Comagro-e1679346089387.jpg`,
          },
          {
            nombre: "José Antonio de Cote",
            cargo: "CHIEF SCIENCE OFFICER & BUSINESS DEVELOPMENT BIOCHEMICAL",
            mail: "jadecote@comagrousa.us",
            tel: "+56 9 6909 2045",
            img: `${require("../assets/Jose-Antonio-De-Cote.png")}`,
          },

          {
            nombre: "Laura Lante",
            cargo: "GRAPHIC DESIGNER AND ADVERTISING INTERNATIONAL",
            mail: "llante@comagrochile.cl",
            tel: "+56 9 8157 2176",
            img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/9-Laura_Lante-e1679346400678.jpg`,
          },

        ],
      },
      
    ],
  };
  export const dataHeroSoluciones = [
    {
      title: "Soluciones",
      text: "Productos formulados para mejorar la eficiencia, en términos de cobertura y absorción de pesticidas, correctores nutricionales, bioestimulantes, reguladores de crecimiento y herbicidas.",
    },
  
   
  ];
export const posts = [
    {
        title: "TECHNICAL ADVICE",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/hossein-ghaem-mtq18OS02Jg-unsplash-scaled.jpg`,
        content: "Our technical department will carry out comprehensive and field advice to accompany our clients throughout the cycle of their cultivation."
    },
    {
        title: "PRECISION FARMING",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/andrea-cairone-Jkfa0AzUEUQ-unsplash-scaled.jpg`,
        content: "Thanks to our associated brands, we can monitor and analyze agronomic and environmental parameters remotely."
    },
    {
        title: "IRRIGATION SYSTEMS",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/www-zanda-photography-RBdE3jv5y68-unsplash-scaled.jpg`,
        content: "Water is the determining factor in agricultural production. Knowing the availability and optimizing its use to achieve higher productions is possible with our custom solutions."
    },
    {
        title: "LABORATORY",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/chuttersnap-UmncJq4KPcA-unsplash-scaled.jpg`,
        content: "We offer complete laboratory analysis for our clients, both foliar analysis and soil analysis."
    },
    {
        title: "WAREHOUSE AND DISTRIBUTION",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/hannes-egler-nT4k2JDtwTQ-unsplash-scaled.jpg`,
        content: "Complete storage service, with a large storage capacity, plus a complete security service. We manage through SAP the expiration control of products that ensure the traceability of our products."
    },
    {
        title: "FINANCING",
        img: `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/micheile-dot-com-SoT4-mZhyhE-unsplash-scaled.jpg`,
        content: "We have the necessary support and guarantees to agree on purchase terms according to the needs of our clients."
    },
    
];