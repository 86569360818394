import { useState } from "react";
import { Dropdown, DropdownButton, Image, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
  const [btnActive, setBtnActive] = useState("usa");
  return (
    <Navbar collapseOnSelect expand="lg" className="App-header">
      <Container className="header-style">
        <Navbar.Brand href="/">
          <Image
            src={require("../../../assets/COMAGRO_logo_empresas_USA.jpg")}
            style={{ width: "124px", height: "50px" }}
          ></Image>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-xl" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto Navbar-header">
            <NavDropdown title="About us" id="collasible-nav-dropdown">
              <Stack gap={2}>
                <NavDropdown.Item href="/quienes-somos">
                About us
                </NavDropdown.Item>
                <NavDropdown.Item href="/equipo">Team</NavDropdown.Item>
              </Stack>
            </NavDropdown>
            <Nav.Link href="/soluciones">Solutions</Nav.Link>
{/*             <Nav.Link href="/noticias">Noticias</Nav.Link> */}
            <Nav.Link href="/contacto">Contact</Nav.Link>
            <Nav.Link
              href="/cotizar"
              className="text-white border hover-nav-item"
            >
              Quotation
            </Nav.Link>
          </Nav>
          <Nav>
            <Link
              to="http://comagrochile.cl"
              onClick={() => {
                setBtnActive("chile");
              }}
              className={
                btnActive === "chile"
                  ? "btn-country-active"
                  : "btn-country-inactive"
              }
            >
              Chile
            </Link>
            <Link
              to="http://comagroperu.pe"
              onClick={() => {
                setBtnActive("peru");
              }}
              className={
                btnActive === "peru"
                  ? "btn-country-active-peru"
                  : "btn-country-inactive-peru"
              }
            >
              Perú
            </Link>
            <Link
              to="http://comagrousa.us"
              onClick={() => {
                setBtnActive("usa");
              }}
              className={
                btnActive === "usa"
                  ? "btn-country-active-usa"
                  : "btn-country-inactive-usa"
              }
            >
              EEUU
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
