import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Carousel from "./multi-item-carousel";
const CarouselHome = () => {
  const [ show, setShow ] = useState(4)
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    console.log(windowSize);
    if(windowSize.innerWidth < 900 & windowSize > 767) {
      setShow(3)
    }else{
      setShow( windowSize.innerWidth <= 767 ? 1 : 4)
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      console.log('show',show);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  return (
    <div
      style={{
        maxWidth: 1200,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Carousel show={show}>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/flowering-up.png")}
                alt="product"
              />
              <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/vitagrow-forte.png")}
                alt="product"
              />
           <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/setting-up.png")}
                alt="product"
              />
            <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/regen-up.png")}
                alt="product"
              />
        <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/oligo-cap-znmn.png")}
                alt="product"
              />
              <button className="carousel-btn carousel-btn-text">Learn more</button>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/oligo-cap-zn.png")}
                alt="product"
              />
        <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/oligo-cap-mn.png")}
                alt="product"
              />
              <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/oligo-cap-mg.png")}
                alt="product"
              />
                   <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/oligo-cap-b.png")}
                alt="product"
              />
        <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/oligo-cap-ca.png")}
                alt="product"
              />
        <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
        <div style={{ marginBlock: "10px", marginInline: "28.5px" }}>
          <div className="overflow-hidden w-[248px] card-carousel">
            <div className="flex flex-col align-center gap-y-[8px]">
              <img
                className="object-cover "
                src={require("../../assets/logos/oligo-cap-k.png")}
                alt="product"
              />
        <Link to="soluciones" className="carousel-btn carousel-btn-text">learn more</Link>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};
export default CarouselHome;
