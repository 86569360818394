export const HEADER_FOOTER_ENDPOINT = `${ process.env.REACT_APP_PUBLIC_BACKEND_URL }/wp-json/rae/v1/header-footer?header_location_id=hcms-menu-header&footer_location_id=hcms-menu-footer`;
export const GET_PRODUCTS_ENDPOINT = `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/api/get-products`;
export const GET_POSTS_ENDPOINT = `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-json/wp/v2/posts?_embed`;


/**
 * Cart
 * @type {string}
 */
export const CART_ENDPOINT = `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-json/rae/v1/cart/items/`;

// Countries and States
export const WOOCOMMERCE_COUNTRIES_ENDPOINT = `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-json/rae/v1/wc/countries/`;
export const WOOCOMMERCE_STATES_ENDPOINT = `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-json/rae/v1/wc/states`;

//contact form
export const CONTACT_FORM_POST = `${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-json/contact-form-7/v1/contact-forms/334/feedback`;