import React from "react";

export default function SiteHero({heading, subheading, imageurl}) {
  const homeContainerStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${imageurl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    maxHeight:'93vh'
  };
  return (
    <div className="container-hero" style={homeContainerStyle}>
      <div className="col-xxl-12" style={{height:'100vh'}}>
        <div className="row align-items-center h-100">
          <div className="col-12 justify-content-center">
                <h1 className="home-hero-title text-white" dangerouslySetInnerHTML={{__html: heading}}/>
                <div className="d-grid col-12 gap-2 ">
                <p className="subheading-hero text-white"dangerouslySetInnerHTML={{__html: subheading}}/>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}
