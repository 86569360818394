import { posts } from "../utils/data";

const Services = () => {
  return (
    <div
      style={{
        display: "grid",
        justifyItems: "center",
        backgroundColor: "#f1f5f9",
      }}
    >
      <h2 className="service-title comagro-blue" style={{ marginTop: "148px" }}>
        Services
      </h2>
      <div
        className="pt-[40px] grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5"
        style={{ marginBottom: "148px" }}
      >
        {posts &&
          posts.map((service) => (
            <div className="rounded-[12px] overflow-hidden shadow-lg w-[304px]">
              <div className="h-[200px]">
                <img
                  className="object-cover image-service"
                  src={service.img}
                  alt="servicio img"
                />
              </div>
              <div className="content-card">
                <div
                  className={`${
                    service.title === "WAREHOUSE AND DISTRIBUTION"
                      ? "service-card-title-2"
                      : "service-card-title"
                  }`}
                >
                  {service.title}
                </div>
                <p className="service-card-content">{service.content}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Services;
