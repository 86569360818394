import { CONTACT_FORM_POST } from "./constants/endpoints";
import { data } from "autoprefixer";
import axios from "axios";

/**
 * Get Products.
 *
 * Endpoint /api/get-products or '/api/get-products?perPage=2'
 *
 * @param req
 * @param res
 * @return {Promise<void>}
 */
export default async function postForm(dataform = {}) {
  const { name, email, message, telefono, comuna, empresa, title } = dataform;
  let responseData = {
    success: false,
    response: {},
  };

  try {
    const FormData = require("form-data");
    const dataForm = new FormData();
    dataForm.append("your-name", name || "");
    dataForm.append("your-subject", "Contacto desde comagrochile.cl");
    dataForm.append("your-email", email || "");
    dataForm.append("your-message", message || "");
    dataForm.append("your-comuna", comuna || "");
    dataForm.append("your-empresa", empresa || "");
    dataForm.append("your-telefono", telefono || "");
    dataForm.append("your-titulo", title || "Desde Contacto");
    const config = {
      method: "post",
      url: CONTACT_FORM_POST,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: dataForm,
    };
    // console.log('config',config);
    // console.log(await axios.post(config));
    const { data } = await axios(config);

    responseData.success = true;
    responseData.data = data;

    return responseData;
  } catch (error) {
    responseData.error = error.message;
  }
}
