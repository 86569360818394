import "./App.css";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Layouts/Footer/footer";
import Header from "./components/Layouts/Header/header";
import Home from "./pages/index";
import Somos from "./pages/Equipo/quienes-somos";
import Equipo from "./pages/Equipo/equipo";
import Soluciones from "./pages/Soluciones";
import Noticias from "./pages/Noticias/index";
import Contacto from "./pages/Contacto";
import Cotizacion from "./pages/Cotizacion";
import Noticia from "./pages/Noticias/Noticia/index"
function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/quienes-somos"  element={<Somos/>}/>
        <Route path="/equipo"  element={<Equipo/>}/>
        <Route path="/soluciones"  element={<Soluciones/>}/>
{/*         <Route path="/noticias"element={<Noticias/>}/>
        <Route path="/noticias/noticia/:postId"  element={<Noticia />}/> */}
        <Route path="/cotizar"  element={<Cotizacion/>}/>
        <Route path="/contacto"  element={<Contacto/>}/>
        <Route path="*"  element={<h1>Not found</h1>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
