import { useState } from "react";
import postForm from "../../utils/send-form";
import Map from '../../components/Map'


const Contacto = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [telefono, setTelefono] = useState("");
  const [comuna, setComuna] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [validate, setValidate] = useState(true);
  const [enviado, setEnviado] = useState(true);
  const [alerta, setAlerta] = useState("");

  const alert = (msje) => {
    return(
      <span className="inline-block align-middle mr-8 text-red-600 text-[10px]">
        <b className=""> {msje}</b> es requerido *
      </span>
    )
  }
  const formAlert  = (msje) => {
    return(
      <span className="inline-block align-middle mr-8 text-gray-600 text-[10px]">
        <b className=""> {msje}</b> 
      </span>
    )
  }
  async function handleSubmit() {
    if(!name){
      setAlerta("nombre")
      setValidate(false);
      setEnviado(true)
      return
    }
    if(!email){
      setAlerta("email");
      setValidate(false);
      setEnviado(true)
      return
    }
    if(!telefono){
      setAlerta("telefono");
      setValidate(false);
      setEnviado(true)
      return
    }
    if(!message){
      setAlerta("mensaje");
      setValidate(false);
      setEnviado(true)
      return
    }
    setValidate(true);
    console.log('enviando form');
    const data = {
      name,
      email,
      message,
      telefono,
      comuna, 
      empresa
    };
    const response = await postForm(data);
    if(response.data.status === "mail_sent"){
      setEnviado(!enviado) 
      setAlerta("Correo enviado con éxito.")
      setName("")
      setEmail("")
      setMessage("")
      setTelefono("")
      setComuna("")
      setEmpresa("")
    }else{
      setEnviado(!enviado)
      setAlerta("Se ha producido un error, inténtelo nuevamente.")
    }
  };

  return (
    <div className="flex flex-col justify-center mx-auto bg-hero-color">
      <div className="mx-auto container lg:w-2/3 pt-20 pb-10 md:px-8 lg:px-0">
          <div className="flex justify-center">
            <h2 className="font-bold text-34px comagro-blue">Contáctanos </h2>
          </div>
          <div className="grid grid-cols-1  mx-auto lg:w-1/2 gap-4 mt-5">
            <div className="relative">
              <input
                type="text"
                id="name"
                name="name"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e)=> {setName(e.target.value);}}
                value={name}
              />
              <label
                htmlFor="name"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Nombre
              </label>
            </div>
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e)=> {setEmail(e.target.value);}}
                value={email}
              />
              <label
                htmlFor="email"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Email
              </label>
            </div>
            <div className="relative">
              <input
                type="number"
                id="telefono"
                name="telefono"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                onChange={(e)=> {setTelefono(e.target.value);}}
                value={telefono}
              />
              <label
                htmlFor="telefono"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Teléfono
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                id="comuna"
                name="comuna"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e)=> {setComuna(e.target.value);}}
                value={comuna}
              />
              <label
                htmlFor="comuna"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Comuna
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                id="empresa"
                name="empresa"
                className="block px-2.5 pb-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                onChange={(e)=> {setEmpresa(e.target.value);}}
                value={empresa}
              />
              <label
                htmlFor="empresa"
                className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-hero-color px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Empresa
              </label>
            </div>
          </div>

          <div className="relative my-4 mx-auto lg:w-1/2 ">
            <textarea
              className="text-area-form h-32 px-2.5 pt-4 w-full text-[14px] rounded-[10px] border-[2px] border-form-b bg-hero-color appearance-none hover:border-black focus:border-primary focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
              id="msje"
              name="msje"
              onChange={(e)=> {setMessage(e.target.value);}}
              value={message}
            ></textarea>
            <label
              htmlFor="msje"
              className="absolute text-[16px] duration-300 transform -translate-y-4 scale-75 top-2  origin-[0] bg-hero-color px-2 peer-focus:px-2  peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Mensaje
            </label>
          </div> 
          <div className="flex flex-row justify-between my-2 mx-auto lg:w-1/2 ">
            <button className="send-btn-form" onClick={() =>handleSubmit()}>Enviar</button>
            {!validate ? alert(alerta) : null}
            {!enviado ? formAlert(alerta) : null}
          </div>
          
      </div>
      <div className="md:container map-container lg:mx-auto">
        <Map/>
      </div>
    </div>
  );
};
export default Contacto;
