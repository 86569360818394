import { isEmpty } from "lodash";
import { Product } from "../Soluciones/Product/";

const Products = ({ data }) => {
   
  if(isEmpty(data)){
    return null
  }
  return (
    
    <div className="grid w-100 justify-center py-[128px] bg-hero-color px-[10px] lg:px-0" 
    style={{rowGap:'32px'}}
    >
      {data &&
        data.length > 0 &&
        data.map((producto) => {
          return (
            <div className="flex max-w-[662px] min-h-[303px]" key={producto.id}>
                <Product product={producto}/>
            </div>
   
          );
        })}
    </div>
  );
};

export default Products;
