const HomeFinal = () => {
  return (
    <div
      className="season-products-div"
      style={{
        background: `linear-gradient(0deg, rgba(164, 194, 72, 1), rgba(164, 194, 72, .55)), url(${process.env.REACT_APP_PUBLIC_BACKEND_URL}/wp-content/uploads/2023/03/randy-fath-dDc0vuVH_LU-unsplash-scaled.jpg) center center / cover no-repeat`,
      }}
    >
      <h2 className="flex justify-center mb-10 text-center text-2xl text-white font-bold md:text-3xl px-1">
        Recommended of the Season
      </h2>
      <div className="mx-auto grid w-fit pt-10 gap-y-20 lg:gap-x-[112px] xl:grid-cols-2">
        <div className="rounded-[12px] overflow-hidden shadow-lg max-w-[300px] max-h-[365px]">
          <div >
            <img
              className="season-image-service object-cover max-h-[202px] w-100 bg-white"
              src={require("../assets/logos/regen-up.png")}
              alt="servicio img"
            />
          </div>
          <div className="season-content-card bg-white">
            <div className="service-card-title comagro-blue">REGEN UP</div>
            <p className="season-card-content">
            Natural rooting agent that favors rooting in all those stages where this process is of special importance, such as transplants, regeneration of damaged root system, etc.
            </p>
          </div>
        </div>
        {/* <div className="season-products-divider "></div> */}
        <div className="rounded-[12px] overflow-hidden shadow-lg max-w-[300px] max-h-[365px]">
          <div className="max-w-[300px] max-h-[202px] bg-white">
            <img
              className="season-image-service object-cover max-h-[202px] w-100" 
              src={require("../assets/logos/vitagrow-forte.png")}
              alt="servicio img"
            />
          </div>
          <div className="season-content-card bg-white">
            <div className="service-card-title comagro-blue">VITAGROW FORTE</div>
            <p className="season-card-content">
            Amino acids from enzymatic hydrolysis of vegetable proteins enriched with bioencapsulated micronutrients and NPK. Inducer of photosynthetic activity of crops and regenerator of plants against any cause of abiotic stress. Recommended in all types of crops.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFinal;
