const WooCommerceRestApi = require("@woocommerce/woocommerce-rest-api").default;

const api = new WooCommerceRestApi( {
	url: process.env.REACT_APP_PUBLIC_BACKEND_URL,
	consumerKey: process.env.REACT_APP_WC_CONSUMER_KEY,
	consumerSecret: process.env.REACT_APP_WC_CONSUMER_SECRET,
	wpAPI: true,
	version: "wc/v3",
	axiosConfig: {
		headers: {}
	  }
} );

/**
 * Get Products.
 *
 * @return {Promise<void>}
 */
export const getProductsData = async ( perPage = 50,ctgry ) => {
	return await api.get(
		'products',
		{
			per_page: perPage || 50,
			orderby: 'title',
			order: 'asc',
			category: ctgry
		},
	);
};

/**
 * Get Single Product By Slug.
 *
 * @return {Promise<void>}
 */
export const getProductBySlug = async ( productSlug = '' ) => {
	return await api.get(
		'products',
		{
			slug: productSlug,
		},
	);
};
