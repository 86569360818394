import { isEmpty } from "lodash";
import { useContext, useState } from "react";
import { addToCart } from "../../utils/cart/index";
import { AppContext } from "../context";

const AddToCart = ({ product }) => {
  const {cart, setCart} = useContext(AppContext);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0)

  const addToCartBtnClass =
    isAddedToCart && !loading
      ? "flex align-center mx-auto text-white hover:text-white"
      : "flex align-center mx-auto hover:text-primary";

  if (isEmpty(product)) {
    return null;
  }
  const handleClick = (quantity) => {
    console.log('aqui')
    console.log(quantity);
    if (quantity >= 0) {
      setQuantity(quantity + 1);
      return;
    }
  };
  const handleRemoveClick = (quantity) => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      return;
    }
  };

  return (
    <>
    <div>
      <button
        className={addToCartBtnClass}
        //addToCart(product id, quantity, setIsAdddedToCart, setLoading)
        onClick={() => {
          addToCart(product?.id ?? 0, quantity, setCart, setIsAddedToCart, setLoading);
        }}
        disabled={loading | isAddedToCart | (!isAddedToCart & quantity === 0) }
      >
        {isAddedToCart && !loading ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-[18px] h-[21px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
            <p className="pl-2 my-auto">Producto agregado</p>
          </>
        ) : (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-[18px] h-[21px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>
            <p className="pl-2 my-auto"> {loading? "Agregando..." : "Agregar a la cotización"}</p>
          </>
        )}
      </button>
    </div>
    <div className="flex align-center mx-auto">
    <button onClick={ () => handleClick(quantity) } >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        className="w-6 h-6 hover:text-primary" 
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
    </button>
    <div className="px-[8px]">{quantity}</div>
    <button onClick={ () => handleRemoveClick(quantity)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        className="w-6 h-6 hover:text-primary"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 12h-15"
        />
      </svg>
    </button>
  </div>
  </>
  );
};

export default AddToCart;
