import React, { useState, useEffect } from "react";
export const AppContext = React.createContext([{}, () => {}]);

export const AppProvider = (props) => {
  const [cart, setCart] = useState(null);
  const [category, setCategory] = useState(null);
  const data = {
    cart,
    setCart,
    category,
    setCategory
  }
  //const [category, setCategory] = useState(null)

  /**
   * This will be called once on initial load ( component mount ).
   *
   * Sets the cart data from localStorage to `cart` in the context.
   */
/*   useEffect(() => {
    if (process.browser) {
      let cartData = localStorage.getItem("next-cart");
      cartData = null !== cartData ? JSON.parse(cartData) : "";
      setCart(cartData);
    }
  }, []); */

  /**
   * 1.When setCart() is called that changes the value of 'cart',
   * this will set the new data in the localStorage.
   *
   * 2.The 'cart' will anyways have the new data, as setCart()
   * would have set that.
   */
/*   useEffect(() => {
    if (process.browser) {
      localStorage.setItem("next-cart", JSON.stringify(cart));
    }
  }, [cart]); */
  useEffect(() => {
    if (window.localStorage.getItem("cart-usa") !== null || window.localStorage.getItem("cart-usa") !== undefined){
      const cartData = JSON.parse(localStorage.getItem("cart-usa"));
      if (cartData) {
        setCart(cartData);
      }
    }
  }, []);  

  useEffect(() => {
    if (window.localStorage.getItem("cart-usa") !== null || window.localStorage.getItem("cart-usa") !== undefined){
         localStorage.setItem("cart-usa", JSON.stringify(cart));
    }
  }, [cart])

   useEffect(() => {
    if (window.localStorage.getItem("ctgry") !== null || window.localStorage.getItem("ctgry") !== undefined){
      const categoryData =  localStorage.getItem("ctgry");
      if (categoryData) {
        setCategory(categoryData);
      }
    }
  }, []); 

  useEffect(() => {
    if (window.localStorage.getItem("ctgry") !== null || window.localStorage.getItem("ctgry") !== undefined){
      localStorage.setItem("ctgry", category);
    }
  }, [category]) 

  return (
    <AppContext.Provider value={data}>
      {props.children}
    </AppContext.Provider>
  );
};
